$(document).ready(function () {
  $("a[href*='shoolu.com'").click(function (e) {
    e.preventDefault();
    console.log(e.target)

    var link = e.target.href
    if (link == undefined){
      link = $(e.target).parents('a').attr("href");
    } 
    console.log(link)
    window.location.href= window.home_url + "/shoolu-redirect?redirect=" + encodeURIComponent(link);
  });

  $(".slider").slick({
    slidesToShow: 4,
    autoplay: true,
    slidesToScroll: 4,
    prevArrow:
      '<button type="button" class="slick-prev"><i class="fa fa-arrow-circle-left" aria-hidden="true"></i></button>',
    nextArrow:
      '<button type="button" class="slick-next"><i class="fa fa-arrow-circle-right" aria-hidden="true"></i></button>',
    appendDots: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          centerMode: true,
          arrows: false,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          arrows: false,
          slidesToScroll: 2,
        },
      },
    ],
  });
  var is_mobile = false;

  if ($("#mobileTest").css("display") == "none") {
    is_mobile = true;
  }

  if (is_mobile == false) {
    $("#mainHead").stick_in_parent();
  }
});
